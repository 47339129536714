export var results = [
    {
        key: "family",
        title: "Family",
        description: "Friends and family are important to you. You like to do things together and feel most comfortable with other people.",
        color: "#ff9800",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Family.png",
        rules: [{ question: "buy", answer: "children"}]
    },
    {
        key: "fitness",
        title: "Fitness",
        description: "Sport and fitness are part of your everyday life. You are happy that - in connection with the right food - you also look good.",
        color: "#00abd2",
        link: "https://best.asello.net/fitness/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Fitness.png",
        rules: [{ question: "buy", answer: "fitness"}]
    },
    {
        key: "vegan",
        title: "Vegan",
        description: "You can do without animal products. You enjoy the variety of plant-based foods. But please leave it in its natural state.",
        color: "#756e37",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Vegan.png",
        rules: [{ question: "buy", answer: "vegan"}]
    },
    {
        key: "wellness",
        title: "Wellness",
        description: "Your well-being is important to you. You have found your inner center and take care of yourself. Organic nutrition will help you with this.",
        color: "#c280c7",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Wellness.png",
        rules: [{ question: "buy", answer: "well-being"}]
    },
    {
        key: "gourmet",
        title: "Gourmet",
        description: "You like to enjoy. The kitchen is your stage. Each ingredient can make all the difference. That's why you're constantly looking for the best.",
        color: "#510d13",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Gourmet.png",
        rules: [{ question: "buy", answer: "connoisseur"}]
    },
    {
        key: "trends",
        title: "Trends",
        description: "Curiosity is your mainspring. There is no trend or innovation that you do not know. When it comes to food, the focus is on quality and naturalness.",
        color: "#462a58",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Trends.png",
        rules: [{ question: "buy", answer: "innovations"}]
    },
    {
        key: "eco",
        title: "Eco",
        description: "Organic food and environmental awareness are not foreign words to you. Living together in and with nature is important to you and you want to make your contribution to it.",
        color: "#82ae2c",
        link: "https://best.asello.net/soon/",
        background: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/Eco.png",
        rules: [{ question: "buy", answer: "environment"}]
    }
]
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';


class MyBestQuiz extends HTMLElement {
  connectedCallback() {
    ReactDOM.render(<React.StrictMode>
      <App />
    </React.StrictMode>, this);
  }
}

if (!window.customElements.get("my-best-quiz")) {
  customElements.define('my-best-quiz', MyBestQuiz);
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

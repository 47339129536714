import React, { FormEvent } from 'react';
import { Question } from '../../models/question';
import "./question-view.scss";

interface QuestionProperties {
    item: Question
    selected?: string
    select: (answer: string) => void;
}

export const QuestionView = (p: QuestionProperties) => {

    function onChangeValue(event: FormEvent<HTMLDivElement>) {
        event.preventDefault();

        var value = (event.target as HTMLInputElement).value as string;

        p.select(value);
    }

    var answerList = p.item.answers.map(c => {
        return <label className="material-checkbox" key={c.id}>
            <input key={Math.random()} name={p.item.id} value={c.id} type="checkbox" className="material-control-input" defaultChecked={p.selected === c.id} onChange={ev => onChangeValue(ev)}></input>
            <span className="material-control-indicator"></span>
            <span className="dots"></span>
            <span className="material-control-description">{c.title}</span>
        </label>
    })

    return <div className="question" >
        <div className="question-title">{p.item?.title} </div>

        <div className="answer-list" >
            {answerList}
        </div>
    </div >
};

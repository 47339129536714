import { QuizResult } from "../../models/quiz-result";
import "./complete-view.scss";

export const CompleteView  = (props: { selected: QuizResult, close: () => void}) => {
    var selected = props.selected;

    var resultColor = {
        color: selected.color
    };

    return (<div className="complete-page">
        <div className="complete-header">You are the <br /><span style={resultColor}>{selected.title}-Type!</span></div>
 
        <div className="complete-desc">{selected.description}</div>

        <div className="complete-link">
            <a href={selected.link}  style={resultColor}>Check out YOUR.Best {selected.title} Products!</a>
        </div>

        <div className="complete-close">
            <button onClick={props.close}>Close</button>
        </div>
    </div>);
}


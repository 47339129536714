import { Question } from "./models/question";

export var questionRepo: Question[] = [
    {
        id: "buy",
        title: "I buy organic food because ...",
        answers: [
            {
                id: "children",
                title: "... I want my child (ren) to grow up healthy"
            },
            {
                id: "fitness",
                title: "... I look at my body and my fitness"
            },
            {
                id: "vegan",
                title: "... I only want the best vegan foods"
            },
            {
                id: "well-being",
                title: "... I take care of my well-being"
            },
            {
                id: "connoisseur",
                title: "... I am a connoisseur"
            },
            {
                id: "environment",
                title: "... I also think about the environment when I eat"
            },
            {
                id: "innovations",
                title: "... I value innovations and news"
            }
        ]
    },
    {
        id: "important",
        title: "What is important to you when it comes to eating?",
        answers: [
            {
                id: "quick",
                title: "It mainly needs to be quick to prepare and / or convenient to take away."
            },
            {
                id: "look",
                title: "I go for the look. Freshness and naturalness are key"
            },
            {
                id: "together",
                title: "That we eat together."
            },
            {
                id: "regional",
                title: "I mainly buy seasonal and regional."
            },
        ]
    },
    {
        id: "adjective",
        title: "Which adjective best describes you?",
        answers: [
            {
                id: "practically",
                title: "practically"
            },
            {
                id: "close-to-nature",
                title: "close to nature"
            },
            {
                id: "inventive",
                title: "inventive"
            },
            {
                id: "athletic",
                title: "athletic"
            },
            {
                id: "enjoyable",
                title: "enjoyable"
            },
            {
                id: "curious",
                title: "curious"
            },
            {
                id: "environmentally-friendly",
                title: "environmentally friendly"
            },
        ]
    }
]

/*
Quiz: which organic type are you?
Tick ​​one answer for each question that you most agree with.

I buy organic food because ...
... I want my child (ren) to grow up healthy
... I look at my body and my fitness
... I only want the best vegan foods
... I take care of my well-being
... I am a connoisseur
... I also think about the environment when I eat
... I value innovations and news

What is important to you when it comes to eating?
It mainly needs to be quick to prepare and / or convenient to take away.
I go for the look. Freshness and naturalness are key
That we eat together.
I mainly buy seasonal and regional.

Which adjective best describes you?
practically
close to nature
inventive
athletic
enjoyable
curious
environmentally friendly

Family: Friends and family are important to you. You like to do things together and feel most comfortable with other people.

Fitness: Sport and fitness are part of your everyday life. You are happy that - in connection with the right food - you also look good.

Vegan: You can do without animal products. You enjoy the variety of plant-based foods. But please leave it in its natural state.

Wellness: Your well-being is important to you. You have found your inner center and take care of yourself. Organic nutrition will help you with this.

Gourmet: You like to enjoy. The kitchen is your stage. Each ingredient can make all the difference. That's why you're constantly looking for the best.

Trends: Curiosity is your mainspring. There is no trend or innovation that you do not know. When it comes to food, the focus is on quality and naturalness.

Eco: Organic food and environmental awareness are not foreign words to you. Living together in and with nature is important to you and you want to make your contribution to it.
*/


/*

Quiz: Welcher Organic Typ bist DU?
Kreuze pro Frage eine Antwort an, mit der Du am ehesten übereinstimmst.

Ich kaufe Bio-Lebensmittel, weil …
… ich will das mein(e) Kind(er) gesund aufwachsen
… ich auf meinen Körper und meine Fitness schaue
… ich nur die besten veganen Lebensmittel will
… ich auf mein Wohlbefinden achte
… ich ein Genießer bin
… ich auch beim Essen an die Umwelt denke
… ich Innovationen und Neuigkeiten schätze

Was ist Dir beim Essen wichtig?
Es muss hauptsächlich schnell zuzubereiten und/ oder praktisch zum Mitnehmen sein.
Es kann gerne auch schön aussehen. Das Auge isst ja bekanntlich mit.
Dass man gemeinsam isst.
Ich kaufe hauptsächlich saisonal und regional ein.

Welches Adjektiv beschreibt Dich am besten?
praktisch
naturverbunden
umweltbewusst
erfinderisch
unternehmungslustig
sportlich
genießerisch
neugierig

Family: Freunde und Familie sind Dir wichtig. Du machst gerne Sachen gemeinsam und fühlst Dich unter anderen Menschen am wohlsten.

Fitness: Sport und Fitness gehören bei dir zum Alltag. Du freust dich, dass man dabei – in Verbindung mit dem richtigen Essen – auch noch gut ausschaut.

Vegan: Es geht bei dir auch ohne tierische Produkte. Du genießt die Vielfalt der pflanzlichen Lebensmittel. Aber bitte naturbelassen.

Wellness: Dein Wohlbefinden ist dir wichtig. Du hast deine innere Mitte gefunden und achtest auf dich. Biologische Ernährung hilft dir dabei.

Gourmet: Du genießt gerne. Die Küche ist deine Bühne. Jede Zutat kann den Unterschied machen. Deswegen bist du ständig auf der Suche nach dem Besten.

Trends: Neugier ist deine Triebfeder. Es gibt keinen Trend und keine Innovation die du nicht kennst. Qualität und Natürlichkeit stehen bei Lebensmitteln im Zentrum.

Eco: Ökologische Lebensmittel und Umweltbewusstsein sind für Dich keine Fremdwörter. Dir ist das Zusammenleben in und mit der Natur wichtig und Du möchtest Deinen Beitrag dazu leisten.

*/
import { useState } from "react";
import { Selection } from "../../models/selection";
import { CompleteView } from "../complete-view/complete-view";
import { QuestionView } from "../question-view/question-view";
import { questionRepo } from "../../questions";
import styles from "./wizard.module.scss";
import { results } from "../../results";
import { QuizResult } from "../../models/quiz-result";

export interface WizardProps {
}

export const Wizard = (props: { close: () => void }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedValue, setSelectedValue] = useState<Selection[]>([]);

    var selectedQuestion = questionRepo[selectedIndex];
    var isComplete = !selectedQuestion
    var totalPages = questionRepo.length + 1;

    // var canGoBack = selectedIndex !== 0 && !isComplete;
    // var canGoNext = selectedIndex < totalPages - 1;
    var canComplete = selectedIndex === totalPages - 2;

    // // TODO: can go prev?
    // function prev() {
    //     setSelectedIndex(selectedIndex - 1);
    // }

    // function next() {
    //     if (!selectedValue[selectedIndex])
    //         return;

    //     setSelectedIndex(selectedIndex + 1);
    // }

    // function completeInternal() {

    //     setSelectedIndex(questionRepo.length + 0);
    // }

    function select(answer: string) {
        var val = [...selectedValue];
        val[selectedIndex] = {
            answer: answer,
            question: selectedQuestion.id
        };

        setSelectedValue(val);

        setTimeout(() => {

            if(canComplete) {
                setSelectedIndex(questionRepo.length + 0);
            }
            else {
                setSelectedIndex(selectedIndex + 1);
            }
        }, 500);
    }

    function onClose(ev: any) {
        ev.preventDefault();
        props.close();
    }

    var selected: string | undefined;

    if (selectedQuestion && selectedValue[selectedIndex]) {
        selected = selectedValue[selectedIndex].answer;
    }

    var selectedResult: QuizResult | null = null;

    var wizardHeaderStyle = {
        backgroundColor: "#453630"
    }

    var wizardBackgroundImage = {
        source: "https://asellostatic.blob.core.windows.net/test/my-best-quiz/background.png"
    }

    if (isComplete) {
        console.log("answers", selectedValue);

        // find result
        selectedResult = results.filter(c => {
            return c.rules.every(j => {
                var selection = selectedValue.filter(j => j.question)[0];

                if (!selection)
                    return true;

                return selection.answer === j.answer;
            })
        })[0];

        // default to forst
        if (!selectedResult)
            selectedResult = results[0];

        wizardHeaderStyle.backgroundColor = selectedResult.color;
        wizardBackgroundImage.source = selectedResult.background;
    }

    return (<div className={styles.wizardHost}>
        <div className={styles.wizardHeader} style={wizardHeaderStyle}>
            <div className={styles.wizardHeaderTitle}>
                MY Best.Quiz
            </div>

            <button className={styles.closeBtn} onClick={onClose}>
                <svg className={styles.closeBtnSvg} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001">
                    <path fill="currentColor" d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                        L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                        c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                        l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                        L284.286,256.002z" />
                </svg>
            </button>

            <p className={styles.wizardHeaderText}>
                What organic-type are you?
            </p>
            <p className={styles.wizardHeaderSmall}>
                Tick one answer for each questions that you most agree with.
            </p>
        </div>

        <div className={styles.wizardContent}>
            {selectedQuestion ? <QuestionView item={selectedQuestion} select={select} selected={selected}></QuestionView> : <></>}
            {!selectedQuestion ? <CompleteView selected={selectedResult!} close={props.close}></CompleteView> : <></>}
        </div>

        {/* <div className={styles.wizardFooter}>
            {canGoBack ? <button className={styles.wizardFooterButton} onClick={prev}>Back</button> : <></>}
            <span className={styles.spacer}></span>
            {canGoNext && !canComplete ? <button className={styles.wizardFooterButton} onClick={next} disabled={!selectedValue[selectedIndex]}>Next</button> : <></>}
            {canComplete ? <button className={styles.wizardFooterButton} onClick={completeInternal}>Complete</button> : <></>}
        </div> */}

        {/* <div className={styles.background}>
            <img alt="Background" className={styles.backgroundImg} src="https://asellostatic.blob.core.windows.net/test/my-best-quiz/background.png"></img>
        </div> */}

        <div className={styles.background}>
            <img alt="Background" className={styles.backgroundImg} src={wizardBackgroundImage.source}></img>
        </div>

    </div>)
}
import React from 'react';
import Modal from 'react-modal';
import './App.scss';
import { Wizard } from './components/wizard/wizard';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0'
  },
  overlay: {
    zIndex: 9999
  }
};

const buttonStyle = {
  border: "#453630",
  backgroundColor: "#453630",
  color: "white",
  // borderRadius: "4px",
  // padding: "7px 15px",
}

Modal.setAppElement('body')

export default class App extends React.Component<{}, { isOpen: boolean }> {
  constructor(props: {}) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  openModal() {
    this.setState({
      isOpen: true
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';

    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
  }

  onAfterClose() {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  closeModal() {
    this.setState({
      isOpen: false
    });
  }

  render() {
    return (
      <React.StrictMode>
        <button style={buttonStyle} onClick={() => this.openModal()} >Start here</button>
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={this.state.isOpen}
          onAfterOpen={() => this.afterOpenModal()}
          onAfterClose={() => this.onAfterClose()}
          onRequestClose={() => this.closeModal()}
          style={customStyles}
        >
          <Wizard close={() => this.closeModal()}></Wizard>

        </Modal>
      </React.StrictMode>
    );
  }
}